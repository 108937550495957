<template>
  <div class="homeBody">
    <div class="carouselBody">
      <img
        class="carousel_1"
        :src="require('../../assets/images/home/carousel_2.png')"
      />
      <img
        class="carousel_2"
        :src="require('../../assets/images/home/carousel_2.png')"
      />
    </div>
    <div class="containerBody">
      <div class="firstBox">
        <div class="innerBox">
          <div class="boxHeader">
            <div class="headerTitle" :class="{ weibei: lang }">
              {{ $t("home_introduction") }}
            </div>
            <div class="seeMore" @click="seemore(0)">{{ $t("more") }} >></div>
          </div>
          <div class="innerBody">
            <div class="imgBox">
              <img
                :src="require('../../assets/images/home/placeholder1.png')"
              />
            </div>
            <p class="introduction" style="text-align: justify;">
              {{ $t("home_introduction_1") }}
            </p>
          </div>
        </div>
        <div class="line"></div>
        <div class="innerBox">
          <div class="boxHeader">
            <div class="headerTitle" :class="{ weibei: lang }">
              {{ $t("newTitle") }}
            </div>
            <div class="seeMore" @click="seemore(1)">{{ $t("more") }} >></div>
          </div>
          <div class="innerBody">
            <div class="firstNews" @click="goDetailPage(firstNews)">
              <div class="newsPhoto">
                <el-image
                  style="width: 100%; height: 100%"
                  v-if="firstNews.cover"
                  :src="firstNews.cover"
                  :fit="'cover'"
                ></el-image>
              </div>
              <div class="newsBox">
                <div class="newsTitle" v-if="firstNews.title">
                  {{ firstNews.title[lang?'zh':'en'] }}
                </div>
                <div class="newsContent" v-if="firstNews.content" v-html="firstNews.content[lang?'zh':'en'].split('.')[0]+'.'"></div>
                <div style="color: #8dc63f;text-align: right;" v-if="firstNews.content">{{lang?'点击查看更多信息':'For more information'}}</div>
              </div>
            </div>
            <div class="firstNews" @click="goDetailPage(newsData)">
              <div class="newsPhoto">
                <el-image
                  style="width: 100%; height: 100%"
                  v-if="newsData.cover"
                  :src="newsData.cover"
                  :fit="'cover'"
                ></el-image>
              </div>
              <div class="newsBox">
                <div class="newsTitle" v-if="newsData.title">
                  {{ newsData.title[lang?'zh':'en'] }}
                </div>
                <div
                  v-if="newsData.content"
                  class="newsContent"
                  v-html="newsData.content[lang?'zh':'en'].split('.')[0]+'.'"
                ></div>
                <div style="color: #8dc63f;text-align: right;" v-if="newsData.content">{{lang?'点击查看更多信息':'For more information'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="secondBox">
        <div class="bigButton" @click="jumpPage(0)">
          <img :src="require('../../assets/images/home/bigButton_left.png')" />
          <div class="button">
            <span class="b_name">{{ $t("participants_registration") }}</span>
            <span class="b_arrow">→</span>
          </div>
        </div>
        <div class="placeholder"></div>
        <div class="bigButton" @click="jumpPage(1)">
          <img :src="require('../../assets/images/home/bigButton_right.png')" />
          <div class="button">
            <span class="b_name">{{ $t("abstract_submission") }}</span>
            <span class="b_arrow">→</span>
          </div>
        </div>
      </div>
      <div class="thirdBox">
        <div class="boxTitle">
          <div class="headerTitle" :class="{ weibei: lang }">
            {{ $t("topics_title") }}
          </div>
        </div>
        <div class="topicBox">
          <template v-for="(lines, index) in topics">
            <div class='lineBox' :key="index">
              <div class="topic" v-for="(el, innerindex) in lines" :key="innerindex">
                <div class="image">
                  <img :src="el.img" />
                </div>
                <div class="title weiruan">
                  {{ el.title }}
                </div>
                <div class="content">
                  {{ el.content }}
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="fourthOuter">
        <div class="fourthBox">
          <div class="boxTitle">
            <div class="headerTitle" :class="{ weibei: lang }">
              {{ $t("hosts") }}
            </div>
          </div>
          <div class="partnerBox">
            <div class="partner noIcon" @click="goAnchor(0)">
              <div class="content" :class="{spacing: lang}">
                {{ $t("partner_1") }}
              </div>
            </div>
            <div class="partner noIcon" @click="goAnchor(0)">
              <div class="content" :class="{spacing: lang}">
                {{ $t("partner_2") }}
              </div>
            </div>
          </div>
        </div>
        <div class="fourthBox">
          <div class="boxTitle">
            <div class="headerTitle" :class="{ weibei: lang }">
              {{ $t("organisers") }}
            </div>
          </div>
          <div class="partnerBox">
            <div class="partner noIcon" @click="goAnchor(1)">
              <div class="content" :class="{spacing: lang}">
                {{ $t("partner_1") }}
              </div>
            </div>
            <div class="partner noIcon" @click="goAnchor(1)">
              <div class="content content_pc" :class="{spacing: lang}" v-html='$t("partner_3")'></div>
              <div class="content content_phone" :class="{spacing: lang}" v-html='$t("partner_4")'></div>
            </div>
          </div>
        </div>
      </div>
      <div class="tableBox">
        <img class="table" :src="$t('home_table')" />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      topics: [
        [
          {
            img: require("../../assets/images/home/topic1.png"),
            title: this.$t("topics_title_1"),
            content: this.$t("topics_content_1"),
          },
          {
            img: require("../../assets/images/home/topic2.png"),
            title: this.$t("topics_title_2"),
            content: this.$t("topics_content_2"),
          },
        ],
        [
          {
            img: require("../../assets/images/home/topic3.png"),
            title: this.$t("topics_title_3"),
            content: this.$t("topics_content_3"),
          },
          {
            img: require("../../assets/images/home/topic4.png"),
            title: this.$t("topics_title_4"),
            content: this.$t("topics_content_4"),
          },
        ]
      ],
      firstNews: {},
      newsData: [],
    };
  },
  async mounted() {
    const result = await axios.get(
      "https://stage.chinafuturelink.com/fu/api/news?meeting_id=627b6984b4eb1908351e8f77&page=1&limit=10&content_txt=1"
    );
    if (result.status === 200) {
      this.newsData = result.data.map((item) => {
        item.year = new Date(item.publish_date * 1000).getFullYear();
        item.month = new Date(item.publish_date * 1000).getMonth() + 1;
        item.date = new Date(item.publish_date * 1000).getDate();
        return item;
      });
      this.firstNews = this.newsData.splice(0, 1)[0];
      this.newsData = this.newsData.splice(0, 3)[0];
      console.log(this.newsData);
    }
  },
  computed: {
    lang() {
      const localeLang = window.localStorage.getItem("locale") || "zh-CN";
      return localeLang === "zh-CN";
    },
  },
  methods: {
    jumpPage(index) {
      switch (index) {
        case 0:
          window.open(
            "https://barc2022.chinafuturelink.com/#/meeting/activity/detail?activity_id=62902ce72e3cf711044637d3&source_id=62902ce72e3cf711044637d2"
          );
          break;
        case 1:
          window.open("https://barc2022.chinafuturelink.com/barc/link.html");
          break;
        default:
          break;
      }
    },
    goDetailPage(item) {
      window.open(
        `https://barc2022.chinafuturelink.com/#/meeting/news/detail?news_id=${item._id.$id}&meeting_id=${item.meeting_id}`
      );
    },
    goAnchor(index) {
      switch (index) {
        case 0:
          this.$router.push({ name: "account",params:{ type: index } });
          break;
        case 1:
          this.$router.push({ name: "account",params:{ type: index }  })
          break;
        default:
          break;
      }
    },
    seemore(index) {
      switch (index) {
        case 0:
          this.$router.push({ path: "/account" });
          break;
        case 1:
          this.$router.push({ path: "/news" })
          break;
        default:
          break;
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
@media screen and (max-width: 728px) {
  .homeBody {
    width: 100%;
    height: auto;
    overflow: hidden;

    .carouselBody {
      width: 100%;
      overflow: hidden;

      .carousel_1 {
        display: none;
      }

      .carousel_2 {
        width: 100%;
        height: auto;
      }
    }

    .containerBody {
      width: 100%;
      min-height: 0;
      overflow: hidden;

      .headerTitle {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #405E3C;
        cursor: pointer;
        font-size: 20px;
        line-height: 30px;
        font-weight: 700;
        box-sizing: border-box;
        border-top: 2px solid #405E3C;
        border-bottom: 2px solid #405E3C;
      }

      .firstBox {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin-top: 50px;

        .innerBox {
          width: 100%;
          height: auto;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;
          box-sizing: border-box;
          padding: 0 15px;

          &:nth-child(3) {
            margin-top: 59px;
          }

          .boxHeader {
            height: 42px;
            width: 100%;
            margin-bottom: 29px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .seeMore {
              color: #8DC63F;
              font-size: 16px;
              height: 20px;
              line-height: 20px;
              user-select: none;
              cursor: pointer;
            }
          }

          .innerBody {
            width: 100%;
            flex: 1;

            .imgBox {
              width: 185px;
              height: 140px;
              float: left;
              clear: both;
              overflow: hidden;
              line-height: 0;
              margin-top: 5px;
              text-align: left;
            }

            .introduction {
              text-align: left;
              font-size: 14px;
              color: #333333;
              line-height: 21px;
            }
          }

          .firstNews {
            width: 100%;
            margin-bottom: 13px;
            cursor: pointer;
            padding-bottom: 15px;
            border-bottom: 1px solid #CCCCCC;

            .newsPhoto {
              width: 170px;
              height: 134px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .newsBox {
              height: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              justify-content: flex-start;
              text-align: left;

              .newsTitle {
                width: 100%;
                font-size: 16px;
                color: #333333;
                line-height: 20px;
                margin-top: 20px;
                display: -webkit-box; // 谷歌
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1; // 显示几行
                overflow: hidden;
              }

              .newsContent {
                width: 100%;
                font-size: 16px;
                color: #333333;
                margin-top: 16px;
                line-height: 24px;
                margin-top: 14px;
                display: -webkit-box; // 谷歌
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 6; // 显示几行
                overflow: hidden;
              }
            }
          }

          .newsItem {
            width: 100%;
            font-size: 16px;
            line-height: 20px;
            color: #333333;
            text-align: left;
            margin-bottom: 14px;
            padding-top: 14px;
            border-top: 1px solid #CCCCCC;
            display: -webkit-box; // 谷歌
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 8; // 显示几行
            overflow: hidden;
            cursor: pointer;
          }
        }

        .line {
          display: none;
        }
      }

      .secondBox {
        margin-top: 50px;
        width: 100%;
        height: auto;
        overflow: hidden;
        box-sizing: border-box;
        padding: 0 15px;

        .bigButton {
          width: 100%;
          height: 130px;
          overflow: hidden;
          position: relative;

          &:nth-child(3) {
            margin-top: 32px;
          }

          img {
            width: 100%;
            height: 100%;
          }

          .button {
            width: 290px;
            height: 80px;
            background: rgba(141, 198, 63, 1);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .b_name {
              font-size: 20px;
              color: #FFFFFF;
              font-weight: 700;
            }

            .b_arrow {
              color: #405E3C;
              font-size: 24px;
            }
          }
        }

        .placeholder {
          width: 25px;
          height: 100%;
        }
      }

      .thirdBox {
        width: 100%;
        margin-top: 55px;
        box-sizing: border-box;
        padding: 0 15px;

        .boxTitle {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 45px;
        }

        .topicBox {
          width: 100%;
          overflow: hidden;

          .topic {
            width: 100%;
            height: auto;
            margin-bottom: 34px;

            .image {
              width: 100%;
              height: auto;

              img {
                width: 100%;
                height: auto;
              }
            }

            .title {
              font-size: 20px;
              color: #405E3C;
              font-weight: 700;
              text-align: left;
              margin-top: 22px;
            }

            .content {
              margin-top: 12px;
              font-size: 16px;
              line-height: 24px;
              color: #333333;
              text-align: left;
            }
          }
        }
      }

      .fourthBox {
        width: 100%;
        margin: 10px 0 60px 0;
        box-sizing: border-box;
        padding: 0 15px;

        .boxTitle {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 40px;
        }

        .partnerBox {
          width: 100%;
          height: auto;

          .partner {
            height: 102px;
            width: 100%;
            background: #8DC63F;
            margin-bottom: 19px;
            cursor pointer;
          }

          .noIcon {
            display: flex;
            box-sizing: border-box;
            align-items: center;
            justify-content: center;

            .spacing {
              letter-spacing: 2px;
            }

            .content {
              color: #FFFFFF;
              font-size: 16px;
              font-weight: 700;
            }
            .content_pc {
              display: none;
            }
            .content_phone {
              display: block;
            }
          }
        }
      }

      .tableBox {
        height: auto;
        overflow: hidden;
        padding: 0 15px;
        margin: 20px 0 33px 0;

        .table {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (min-width: 728px) {
  .homeBody {
    width: 100%;
    height: auto;
    overflow: hidden;

    .carouselBody {
      width: 100%;
      height: auto;
      overflow: hidden;

      .carousel_1 {
        width: 100%;
        height: auto;
      }

      .carousel_2 {
        display: none;
      }
    }

    .containerBody {
      max-width: 1520px;
      min-height: 0;
      overflow: hidden;
      margin: auto;
      box-sizing: border-box;
      padding 0 60px;
      .headerTitle {
        height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        color: #405E3C;
        cursor: pointer;
        font-size: 28px;
        line-height: 42px;
        font-weight: 700;
        border-top: 2px solid #405E3C;
        border-bottom: 2px solid #405E3C;
      }

      .firstBox {
        width: 100%;
        height: 465px;
        overflow: hidden;
        margin-top: 120px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .innerBox {
          flex: 1;
          height: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: flex-start;

          .boxHeader {
            width: 100%;
            margin-bottom: 45px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .seeMore {
              color: #8DC63F;
              font-size: 16px;
              height: 24px;
              line-height: 24px;
              user-select: none;
              cursor: pointer;
            }
          }

          .innerBody {
            width: 100%;
            flex: 1;

            .imgBox {
              width: 185px;
              height: 140px;
              float: left;
              clear: both;
              overflow: hidden;
              line-height: 0;
              margin-top: 5px;
              text-align: left;
            }

            .introduction {
              text-align: left;
              font-size: 16px;
              color: #333333;
              line-height: 25px;

              &:nth-child(3) {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 5;
                overflow: hidden;
              }
            }
          }

          .firstNews {
            width: 100%;
            display: flex;
            margin-bottom: 13px;
            cursor: pointer;
            padding-bottom: 15px;
            border-bottom: 1px solid #CCCCCC;

            .newsPhoto {
              width: 170px;
              height: 134px;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .newsBox {
              height: 100%;
              flex: 1;
              display: flex;
              flex-direction: column;
              box-sizing: border-box;
              padding-left: 10px;
              justify-content: flex-start;
              text-align: left;

              .newsTitle {
                width: 100%;
                font-size: 16px;
                color: #333333;
                display: -webkit-box; // 谷歌
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1; // 显示几行
                overflow: hidden;
              }

              .newsContent {
                width: 100%;
                font-size: 16px;
                color: #333333;
                margin-top: 16px;
                line-height: 24px;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 4;
                overflow: hidden;
              }
            }
          }

          .newsItem {
            max-height: 44px;
            font-size: 16px;
            color: #333333;
            padding: 0 12px;
            line-height: 44px;
            text-align: left;
            border-bottom: 1px solid #CCCCCC;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            cursor: pointer;
          }
        }

        .line {
          width: 1px;
          height: 400px;
          background: #E0E0E0;
          margin: 0 30px 0 22px;
        }
      }

      .secondBox {
        margin-top: 99px;
        width: 100%;
        height: 260px;
        overflow: hidden;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .bigButton {
          flex: 1;
          height: 100%;
          overflow: hidden;
          position: relative;

          img {
            width: 100%;
            height: 100%;
          }

          .button {
            width: 290px;
            height: 80px;
            background: rgba(141, 198, 63, 1);
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            cursor: pointer;
            box-sizing: border-box;
            padding: 0 30px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .b_name {
              font-size: 20px;
              color: #FFFFFF;
              font-weight: 700;
            }

            .b_arrow {
              color: #405E3C;
              font-size: 24px;
            }
          }
        }

        .placeholder {
          width: 25px;
          height: 100%;
        }
      }

      .thirdBox {
        width: 100%;
        margin-top: 82px;

        .boxTitle {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-bottom: 68px;
        }

        .topicBox {
          width: 100%;
          max-width: 1400px;
          overflow: hidden;

          .lineBox {
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            margin-bottom: 42px;

            .topic {
              flex: 1;
              min-height: 100px;
              overflow hidden;

              &:nth-child(2n+1) {
                margin-right: 24px;
              }

              .title {
                font-size: 20px;
                color: #405E3C;
                font-weight: 700;
                text-align: left;
                margin-top: 30px;
              }

              .image {
                overflow hidden;
                img {
                  display: block;
                }
              }

              .content {
                margin-top: 12px;
                font-size: 16px;
                line-height: 24px;
                color: #333333;
                text-align: left;
              }
            }
          }
        }
      }
      .fourthOuter {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow hidden;
        margin-top: 55px;

        .fourthBox {
          flex 1;
          width: 0;
          &:nth-child(1) {
            margin-right: 56px;
          }

          .boxTitle {
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 38px;
          }

          .partnerBox {
            width: 100%;
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;

            .partner {
              height: 100%;
              flex 1;
              background: #8DC63F;
              cursor pointer;

              &:nth-child(1) {
                margin-right: 20px;
              }
            }

            .noIcon {
              display: flex;
              box-sizing: border-box;
              align-items: center;
              justify-content: center;

              .spacing {
                letter-spacing: 2px;
              }

              .content {
                color #FFFFFF;
                font-size: 20px;
                font-weight: 700;
              }
              .content_pc {
                display: block;
              }
              .content_phone {
                display: none;
              }
            }
          }
        }
      }

      .tableBox {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin: 86px 0 112px 0;

        .table {
          width: 100%;
        }
      }
    }
  }
}
</style>